import { cognito } from "../modules/cognito"

export function getEnvironment() {
  if (window.location.host.includes("localhost")) { return "stage" }
  if (window.location.pathname.includes("staging")) { return "stage" }
  return "prod"
}

export function getHeaders() {
  const tokens = cognito.getLocalTokens()
  if (tokens) {
    // return headers
    return ({
      Authorization: `Bearer ${tokens.accessToken}`
    })
  } else {
    // refresh page to login again
    window.location.href = window.location.pathname
  }
}

export function pushRouter(path) {
  const hash = window.location.hash.replace("#", "")
  window.location.href = hash.length > 0 ? `#${hash}:${path}` : `#${path}`
}

export function loadImages(...paths) {
  return new Promise(resolve => {
    const items = []
    const callback = data => {
      items.push(data)
      if (items.length === paths.length) { resolve(items) }
    }
    for (let i = 0; i < paths.length; i++) {
      const image = new Image()
      image.addEventListener("load", () => callback(image))
      image.addEventListener("error", () => callback(null))
      image.src = paths[i]
    }
  })
}

export function toCoinsUnit(amount) {
  return amount === 1 ? "Coin" : "Coins"
}

export function getGameDetails(data) {
  return data ? [
    data.winnerCount > 0 ? `${data.winnerCount} Winners • ` : "",
    data.paidGame ? `${data.amount} ${toCoinsUnit(data.amount)}` : "FREE"
  ].join(" ") : ""
}
