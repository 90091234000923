/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setGame } from "../services/store"
import { analytics } from "../modules/analytics"
// import home components
import Header from "../components/home/Header"
import Footer from "../components/home/Footer"
import Search from "../components/home/Search"
import Details from "../components/home/Details"
// import pages
import AppsPage from "../pages/AppsPage"
import FavoritesPage from "../pages/FavoritesPage"
import SettingsPage from "../pages/SettingsPage"
// import screens
import Game from "./Game"
// import style
import "./Home.css"

export default function Home() {
  // dispatch
  const dispatch = useDispatch()
  // hash router path
  const router = useSelector(states => states.common.router)
  // all games array
  const games = useSelector(states => states.common.games)
  // current page
  const [page, setPage] = useState("apps")
  // content scrolled state
  const [isScrolled, setIsScrolled] = useState(false)
  // search popup state
  const isSearchOpen = router.includes("search")
  // gameplay active state
  const isGameplay = router.includes("gameplay")
  // method to switch page
  const switchPage = code => {
    if (code === "filter") {
      // jump to search
      document.querySelector(".search-input").click()
    } else {
      // set current page
      setPage(code)
      // scroll page to top
      document.documentElement.scrollTop = 0
    }
  }
  // reset scrolled state on page change
  useEffect(() => setIsScrolled(false), [page])
  // effect on mount
  useEffect(() => {
    // init google analytics
    analytics.init()
    // update scrolled state on scroll
    window.addEventListener("scroll", () => {
      setIsScrolled(document.documentElement.scrollTop > 70)
    })
    // get auto play game id
    const playId = localStorage.getItem("play")
    // check auto play
    if (playId) {
      // remove from storage
      localStorage.removeItem("play")
      // find game by auto play id
      const autoPlay = games.find(item => item.id === parseInt(playId))
      // open game details popup
      if (autoPlay) {
        dispatch(setGame(autoPlay))
        window.location.href = "#view"
      }
    }
  }, [])
  // log page on change
  useEffect(() => {
    analytics.logEvent("VIEW_PAGE", { page_name: page })
  }, [page])
  // home screen
  return (
    <div className="container">
      <div
        className={`home ${page}`}
        data-stick={isScrolled || isSearchOpen}>
        <Header />
        <Search />
        {
          !isGameplay && (
            <>
              {/* switch page by code */}
              {page === "apps" && <AppsPage />}
              {page === "favorites" && <FavoritesPage />}
              {page === "settings" && <SettingsPage />}
              <Footer page={page} onChange={switchPage} />
            </>
          )
        }
      </div>
      {/* game details popup */}
      <Details />
      {/* gameplay screen */}
      <Game active={isGameplay} />
    </div>
  )
}
