import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getHeaders } from "../../utility/helpers"

const favoritesAPI = createApi({
  reducerPath: "favoritesAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  }),
  endpoints: builder => ({
    getFavoriteGames: builder.mutation({
      query: ({ page, limit }) => ({
        url: `/user/v1/favorite-game/${limit}/${page}`,
        method: "GET",
        headers: getHeaders()
      }),
      transformResponse: response => response.data
    }),
    checkFavoriteGame: builder.mutation({
      query: ({ gameId }) => ({
        url: "/user/v1/favorite-game/check",
        method: "GET",
        params: { game_id: gameId },
        headers: getHeaders()
      }),
      transformResponse: response => response.data
    }),
    addFavorite: builder.mutation({
      query: ({ gameId }) => ({
        url: "/user/v1/favorite-game",
        method: "POST",
        params: { game_id: gameId },
        headers: getHeaders()
      })
    }),
    removeFavorite: builder.mutation({
      query: ({ gameId }) => ({
        url: "/user/v1/favorite-game",
        method: "DELETE",
        params: { game_id: gameId },
        headers: getHeaders()
      })
    })
  })
})

export const {
  useGetFavoriteGamesMutation,
  useCheckFavoriteGameMutation,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation
} = favoritesAPI

export default favoritesAPI
