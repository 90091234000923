/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setRouter } from "./services/store"
import Home from "./screens/Home"
import Game from "./screens/Game"
import Login from "./screens/Login"

function App() {
  // dispatch api
  const dispatch = useDispatch()
  // get store states
  const theme = useSelector(states => states.common.theme)
  const animations = useSelector(states => states.common.animations)
  const screen = useSelector(states => states.common.screen)
  // update settings on change
  useEffect(() => {
    localStorage.setItem("theme", theme)
    document.body.setAttribute("data-theme", theme)
    localStorage.setItem("animations", animations)
    document.body.setAttribute("data-animations", animations)
  }, [theme, animations])
  // update hash router on change
  useEffect(() => {
    window.addEventListener("hashchange", () => {
      dispatch(setRouter(window.location.hash))
    })
  }, [])
  // return screen by code
  return { login: <Login />, home: <Home />, game: <Game /> }[screen]
}

export default App
